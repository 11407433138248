// Colors
$teal-lk: #00b0b8;

$eggplant: #3e2a55;
$bone: #f9f7ec;
$river: #0087B5;
$lake: #1B5C97;
$stone: #363A47;
// New shades of green based on 'AdRetriever Brand Identity System Guidelines 2023-V3'
$mint: #E2F5EF;
$sage: #B5DFD4;
$forrest: #0D8760;
$grass: #0F9F71;
$cedar: #009E9F;
$spruce: #007663;
$light-sage: #B7DFD3; 

$surface-primary: #E2F5EE;
$disable: #8C8C8D;

$marigold-v: #fcaf26;
$watermelon-v: #f0536c;
$extra-light-grey: #F4F4F4;
$light-grey-v: #D1D3D4;
$dark-grey-v: #3C3C3C;

$eggplant-translucent: fade-out(rgba($eggplant, .9),.2);

$bg-main: $mint;
$text-main-dark: #555555;
$text-light:rgba(0, 0, 0, 0.5);

// brand colors
$primary: $spruce;
$secondary: #3e2a55;

// darken accents
$primary-dark: darken($primary, 5%);
$secondary-dark: darken($secondary, 10%);
$spruce-dark: darken($spruce, 5%);
$marigold-v-dark: darken($marigold-v, 5%);
$forrest-dark: darken($forrest, 5%);

// lighten accents
$secondary-light: lighten($secondary, 20%);
$secondary-lighter: lighten($secondary, 50%);
$marigold-v-light: lighten($marigold-v, 30%);

// translucent accents
$primary-translucent: fade-out(rgba($primary, .4),.2);
$secondary-translucent: fade-out(rgba($secondary, .9),.2);