@import '../colorVariables.scss';

.table {
    // Support for rounded corners at the top of a table
    &.table-rounded {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;

        thead th {
            border-bottom-width: 1px;
            border-top: 0;
        }

        tr:first-child th:first-child {
            border-top-left-radius: 15px;
        }

        tr:first-child th:last-child {
            border-top-right-radius: 15px;
        }
    }

    // Slightly larger checkboxes in (selectable) tables
    .custom-control-label::before, .custom-control-label::after {
        width: 1.2rem;
        height: 1.2rem;
    }

    // Adjust positioning of checkboxes in tables
    td .custom-control-label::before, td .custom-control-label::after {
        top: 0.5rem;
    }

    // Tone down the styles from BootstrapVue for active & hover states on table rows
    &.b-table>tbody>.table-active {
        background-color: $gray-200 !important;
    }
    &.b-table.table-hover>tbody>tr.table-active:hover td {
        background-image: none !important;
        background-color: $gray-300;
    }

    // Tables whose rows are clickable
    &.table-clickable {
        margin-bottom: 0;

        thead {
            th {
                border-top: 0;
                border-bottom: 0;
                color: white;
                background-color: $forrest;

                a {
                    color: white;
                }
            }

            tr {
                background-color: $bg-main;
            }
        }

        tr {
            background-color: $light;
        }

        td {
            padding: 0;
        }

        td {
            a {
                display: block;
                padding: .75rem;
                text-decoration: none;
            }
        }
    }
}

// Tone down the styles from Bootstrap for active & hover states on table rows
.table-active > td {
    background-color: inherit !important;
}
.table-hover tbody tr:hover {
    background-color: $gray-100;
}