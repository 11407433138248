/* 
*  TABLE OF CONTENTS
*   
*  1.0 - Imports
*  2.0 - Branding
*    2.1 - Buttons
*  3.0 - Overrides
*  4.0 - Breadcrumb
*  5.0 - Pagination (Django's)
*  6.0 - Itemset card
*  7.0 - Logo modal
*  8.0 - Content
*  9.0 - Report
*  10.0 - Item cards
*  11.0 - Router view transition
*  12.0 - Other
*/

/*** 1.0 - Imports ***/
// Base
// Bootstrap and its default variables
@import './colorVariables';
@import './fonts';
@import '~bootstrap/scss/bootstrap';
// BootstrapVue and its default variables
@import '~bootstrap-vue/src/index.scss';
@import '~@braid/vue-formulate/themes/snow/snow.scss';
@import '~vue2-dropzone/dist/vue2Dropzone.min.css';
@import '~@vueform/multiselect/themes/default.css';
@import './bootstrap-vue-overrides';
@import './utilities';
// Components / Pages
@import './components';

/*** 2.0 - Branding ***/
body {
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: $font-size-base;
  background-color: $mint;
  color: $eggplant;
  padding: 70px 0 65px 0;
}

.brand-heading {
  font-family: Rift, OpenSansCondensed, sans-serif;
  font-weight: 700;
}

a {
  color: $spruce;

  &:hover {
    color: $spruce-dark;
  }
}

.tooltip {
  pointer-events: none;
}

.modal-content {
  border-radius: 15px;
  color: $eggplant;
}

.nav.nav-tabs {
  color: $eggplant;

  .nav-link.active {
    font-weight: bold;
    color: $eggplant !important;
  }
}

.dropdown-menu {
  border-radius: 15px;
  color: $eggplant;
  font-size: $font-size-base;
}

.adretriever-logo {
  width: 170px;
  vertical-align: text-bottom;
  margin-left: -1rem; // Correct whitespace of the svg file
}

#main_nav {
  .nav-item {
    .nav-link {
      color: $eggplant;
      font-weight: bold;

      &.active {
        color: $cedar;
      }
    }

    &.active {
      .nav-link {
        color: $cedar;
      }
    }
  }
}

/*** 2.1 - Buttons ***/
.btn-no-outline {
  &:focus, &:active {
    outline: none !important;
    box-shadow: none !important;
  }
}

.btn-primary {
  color: $eggplant;
  border-color: $marigold-v;
  background-color: $marigold-v;
  font-weight: 600;

  &:hover {
    background-color: $eggplant;
    color: $marigold-v;
    border-color: $eggplant;
  }

  &:focus,
  &.focus {
    background-color: $eggplant;
    color: $marigold-v;
    border-color: $eggplant;
  }

  &:disabled {
    color: $eggplant;
    border-color: $marigold-v;
    background-color: $marigold-v;
  }
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
  background-color: $marigold-v;
  border-color: $marigold-v;

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba($marigold-v, .5)
  }
}


.btn-outline-primary {
  color: $primary;
  border-color: $primary;

  &:hover,
  &:active,
  &.active,
  &:focus,
  &.focus {
    background-color: $primary;
    color: $white;
    border-color: $primary;
  }
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
  background-color: $primary;
  color: $white;
  border-color: $primary;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: $primary
}

.btn-secondary {
  color: $white;
  border-color: $secondary;
  background-color: $secondary;

  &:hover {
    background-color: $secondary-dark;
    color: $white;
  }

  &:focus {
    background-color: $secondary;
    border-color: $secondary;
    box-shadow: 0 0 0 .2rem rgba($secondary, .5);
  }
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: $secondary;
  border-color: $secondary;
}

.btn-outline-secondary {
  color: $secondary;
  border-color: $secondary;
  background-color: transparent;

  &:hover {
    background-color: $secondary;
    color: $white;
  }
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show>.btn-outline-secondary.dropdown-toggle {
  background-color: $secondary;
  border-color: $secondary;

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba($secondary, .5)
  }
}

.marigold-button {
  color: #fff;
  border-color: $marigold-v;
  background-color: $marigold-v;

  &:hover {
    border-color: $eggplant;
    background-color: $eggplant;
  }
}

.marigold-button-outline {
  color: $marigold-v;
  border-color: $marigold-v;
  background-color: transparent;

  &:hover {
    border-color: $marigold-v;
    background-color: $marigold-v;
    color: #fff;
  }
}

.marigold-button-footer {
  color: #fff;
  border-color: $marigold-v;
  background-color: $marigold-v;

  &:hover {
    border-color: $teal-lk;
    background-color: $teal-lk;
    color: #fff
  }
}

.watermelon-button {
  color: #fff;
  border-color: $watermelon-v;
  background-color: $watermelon-v;

  &:hover {
    border-color: $eggplant;
    background-color: $eggplant;
  }
}

.btn-spruce {
  background-color: $spruce !important;
  border-color: $spruce !important;
  font-weight: 600;


  &:hover,
  &:active,
  &:focus {
    background-color: $grass !important;
    border-color: $grass !important;
    color: $white;
  }

  &:disabled{
    pointer-events: none;
  }
}

.btn-spruce-outline {
  background-color: transparent !important;
  border-color: $spruce !important;
  color: $spruce;
  font-weight: 600;

  &:hover,
  &:active,
  &:focus {
    background-color: $spruce !important;
    color: $white;
  }

  &:disabled{
    pointer-events: none;
    color: $spruce;
  }
}

.btn-outline{
  &:hover,
  &:active,
  &:focus {
    border-color: $sage !important;
  }
}

// Only displayed as disabled but keep functionality
.btn-disabled{
  opacity: 0.65;
}

// Google Ads Tags generation
.oval-border-button {
  background-color: #fff;
  color: #333;
  border: 2px solid $light-grey-v;
  border-radius: 8px;
  padding: 10px 20px;
  transition: border-color 0.3s ease, background-color 0.3s ease; 
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  width: 100%;
  justify-content: space-between;
}

.oval-border-button:hover {
  border-color: $grass;
  background-color: #fff;
}

.oval-border-button:hover, .oval-border-button:focus {
  color: #333;
}
.confirmation-button-icon {
  font-size: 2rem;
  margin-left: auto; 
  color: $grass;
}

.btn-spruce-white-text {
  @extend .btn-spruce;
  color: $white !important;

  &:hover,
  &:active,
  &:focus {
    color: $white !important;
  }
}

/*** 3.0 - Overrides ***/
h4,
h5,
h6 {
  font-weight: bold;
}

.form-control.h3 {
  font-size: 1.75rem;
}

label {
  font-weight: bold;
}

.dropdown-item {
  &:active {
    background-color: $gray-600;
  }
}

.badge-secondary {
  background-color: $eggplant;
  color: white;
}

.nav-pills .nav-link.active {
  color: $white !important;
  background-color: $secondary;
}

.nav-pills {
  .nav-item {
    .nav-link {
      color: $secondary;

      &:hover {
        color: $secondary-light;
      }
    }
  }
}

.data-src-pills {
  .nav-item {
    .nav-link {
      color: $secondary;

      &:hover {
        color: $secondary-light;
      }
    }
  }
}

.list-group-item.active {
  background-color: $primary;
  border-color: $primary;
}

.btn-group-toggle {
  &.selected {
    background-color: #007663 !important;
    color: #fff !important;
    border-color: #007663 !important;

    .btn {
      color: #fff !important;
    }
  }
}

.alert-primary{
  color: white;
  background-color: $forrest;
  border-color: $spruce;
}

.formulate-input-label{
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/*** 4.0 - Breadcrumb ***/
.breadcrumb {
  padding: 0;
  margin: 0px 100px 5px 0px;
  background-color: transparent;
}

.breadcrumb-item+.breadcrumb-item::before {
  color: #BDBABA;
}

.breadcrumb-container {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.breadcrumb-container:empty {
  padding-top: 0;
  padding-bottom: 0;
}

// Hide breadcrumb border when it is empty
.breadcrumb:has(> .breadcrumb-container:empty),
.breadcrumb:has(> .breadcrumb-container nav ol:empty) {
  opacity: 0;
}

// checkbox and radio custom styling
.custom-checkbox .custom-control-input:checked~.custom-control-label::before,
.custom-radio .custom-control-input:checked~.custom-control-label::before {
  background-color: $primary;
  border-color: $primary;
}

.custom-checkbox .custom-control-input:checked:focus~.custom-control-label::before,
.custom-radio .custom-control-input:checked:focus~.custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem $secondary-lighter;
}

.custom-checkbox .custom-control-input:focus~.custom-control-label::before,
.custom-radio .custom-control-input:focus~.custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem $secondary-lighter;
  border-color: $secondary;
}

.custom-checkbox .custom-control-input:active~.custom-control-label::before,
.custom-radio .custom-control-input:active~.custom-control-label::before {
  background-color: $secondary-light;
}

.formulate-input{
  font-family: unset;
}

.formulate-input[data-classification=text] input.is-invalid {
  border-color: $danger;
}

.formulate-input[data-classification='box'] .formulate-input-element input[type="radio"]:checked~.formulate-input-element-decorator {
  border-color: $secondary !important;
}

.formulate-input[data-classification='box'] .formulate-input-element input[type="radio"]:checked~.formulate-input-element-decorator::before {
  background-color: $secondary !important;
}

.custom-file-upload {
  &:hover {
    color: $secondary;
  }
}

.formulate-input[data-classification="file"] .formulate-input-upload-area-mask {
  &:hover {
    border: 2px dashed $secondary !important;
  }
}

.formulate-input[data-classification="file"] .formulate-input-upload-area-mask::before {
  &:hover {
    background-color: $secondary !important;
  }
}

.formulate-input[data-classification=select] select, .formulate-input[data-classification=text] input{
  background: white !important;
  font-family: unset;
  font-size: $font-size-md;

  &:disabled{
    background-color: #e9ecef !important;
  }
}

/*** 5.0 - Pagination ***/
.page-item.active .page-link {
  background-color: $forrest;
  border-color: $forrest;
}

.page-link {
  color: $forrest;

  &:hover {
    color: $forrest-dark;
  }
}

.action-link {
  width: 3rem;
  text-align: center;
  color: $secondary;

  &:hover {
    color: $dark !important;
  }
}

/*** 6.0 - Itemset card ***/
.itemset-card {
  width: 17.6rem;
  margin: 0 auto;
  height: 18.5vw;
  object-fit: contain;

  @media (max-width: 1200px) {
    width: 20rem;
  }

  @media (max-width: 991px) {
    width: 22.7rem;
  }

  @media (max-width: 767px) {
    width: 16.5rem;
  }

  @media (max-width: 576px) {
    width: 80%;
  }

}

.card-img-top {
  width: 100%;
  height: 13vw;
  object-fit: contain;
}

.itemset-card-title {
  // font-size: 1.125rem;
  font-size: 1vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: .8rem;
}

.itemset-card-text {
  font-size: .9rem;
}

.card-titleonly {
  text-align: center;
  width: 100%;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: $secondary;
  bottom: 0;
}

.active-selection {
  box-shadow: $secondary-translucent 0px 5px 15px;
}

/*** 7.0 - Logo modal ***/
.logo-selector {
  justify-content: flex-start;
}

.star-button {
  background-color: transparent;
  color: #808080;
  bottom: 0;
  right: 10px;
  width: 25px;
  height: 25px;
  position: absolute;
  border: none;
  cursor: pointer;
}

.star-button:before {
  content: "\2605"; // star Unicode character
  font-size: 18px;
}

.star-button:hover:before {
  color: #A9A9A9; //darker grey color
}

.star-button:focus {
  outline: none;
}

.star-button-default {
  color: black;
  pointer-events: none;
}

.icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  max-height: 100%;

  @media (max-width: 1000px) {
    width: 90%;
  }

  @media (max-width: 750px) {
    width: 75%;
  }
}

.logo-button-size {
  height: 100px;
  width: 400px;
}

.upload-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh
}

.upload-logo .upload-input-size {
  width: 250px;
}

/*** 8.0 - Content ***/
.content__product,
.campaign_product {
  align-items: center;
  background: white;
  border: 1px solid rgba(53, 37, 74, 0.2);
  border-radius: 4px;
  display: grid;
  height: 180px;
  justify-items: center;
  overflow: hidden;
  padding: 4px;
  position: relative;
  width: 180px;
}

.content__product:hover .content__product-description {
  bottom: 0%;
}

.content__product-description {
  background: white;
  bottom: -100%;
  font-size: 10px;
  font-weight: bold;
  padding: 5px;
  position: absolute;
  text-align: center;
  transition: bottom 0.2s ease;
  width: 100%;
}

.content__product-image {
  max-height: 100%;
  max-width: 100%;
}

.content__theme-image {
  max-height: 206px;
  max-width: 246px;
}

.content__theme {
  align-items: center;
  border: 1px solid rgba(53, 37, 74, 0.2);
  border-radius: 4px;
  cursor: pointer;
  display: grid;
  height: 256px;
  justify-items: center;
  overflow: hidden;
  padding: 4px;
  position: relative;
  width: 256px;
}

.content__theme-description {
  background: white;
  bottom: 0%;
  font-size: 10px;
  font-weight: bold;
  padding: 5px;
  position: absolute;
  text-align: center;
  transition: bottom 0.2s ease;
  width: 100%;
}

/*** 9.0 - Report ***/
.report_table {
  border-collapse: collapse;
  width: 100%;
  text-align: middle;
}

.report_table__header {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  color: white;
  background-color: #3E2A55;
  text-transform: capitalize;
}

.report_table__row:nth-child(even) {
  background-color: #F9F7EC;
}

.report_table__row:hover {
  background-color: rgba(62, 42, 85, 0.2);
}

.custom-file input[type="file"]:hover {
  cursor: pointer;
}

.custom-file input[type="file"]:hover+.custom-file-label::after {
  cursor: pointer;
  background: $primary;
  color: white;
}

.selected {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

/*** 10.0 - Item cards ***/
.overlay {
  opacity: 0.5;
  background-color: #000000;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.checkbox_filter {
  top: 20px;
  left: 20px;
  position: absolute;
  cursor: pointer;
}

.btn_zoom {
  top: 130px;
  left: 130px;
  position: absolute;
  cursor: pointer;
}

.white-thumbtack {
  color: white;
  transform: rotate(-35deg);
  margin-left: -5px;
}

.fancy-checkbox {
  position: absolute;
  top: 0;
  left: 0;
}

.fancy-checkbox input[type="checkbox"],
.fancy-checkbox .checked {
  display: none;
  font-size: 3rem;
}

.fancy-checkbox .unchecked {
  display: none;
  font-size: 3rem;
}

.fancy-checkbox input[type="checkbox"]:checked~.checked {
  display: inline-block;
  font-size: 3rem;
}

.fancy-checkbox input[type="checkbox"]:checked~.unchecked {
  display: none;
  font-size: 3rem;
}

.filter:hover .fancy-checkbox .unchecked {
  display: block;
}

/*** 11.0 - Router view transition ***/
.fade-enter-active, .fade-leave-active {
  transition: opacity .05s ease;
}

.fade-enter, .fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/*** 12.0 - Other ***/
.fa-image {
  font-size: 2rem;
  color: #333;
}

.pseudolink {
  color: $primary !important;
  cursor: pointer;
}

.loading {
  background: url(/static/img/retrieving.gif) center center no-repeat;
  background-size: 100px 100px;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  white-space: initial;
  color: $danger;
  word-break: break-word;
}

.accordion-toggle {
	.expand-button {
		&:after {
			position: absolute;
			left: .75rem;
			top: 50%;
			transform: translate(0, -50%);
			content: '-';
		}
	}
  &.collapsed {
    .expand-button {
      &:after {
        content: '+';
      }
    }
  }
}

.filter {
  position: relative;
}

.badge .close {
  margin-left: .25rem;
  color: inherit;
  font-size: 100%;
}

.badge .close .fa {
  font-size: 0.5rem;
  vertical-align: middle;
}

.learning-set-container {
  .item-sets-content {
    height: calc(100vh - 18rem);
    overflow: hidden;
  }
  .items-container {
    height: calc(100vh - 22rem);
    overflow: auto;
  }
}

.wrapped_td {
  min-width: 160px;
  max-width: 160px;
  overflow: hidden;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.filter-pill {
  white-space: pre-wrap;
  width: fit-content;

  .hide {
    display: none;
  }
}

// background video form styling
.label-heading,
.btn-modal {
  margin-bottom: .5rem;
}

// JS header copy-cat of Django templates
.custom-header {
  margin-top: -5.5rem;
  margin-bottom: 2rem;
}

// Share Ad modal
#share-modal {
  .share-field {
    margin: 15px 0px -5px 0px;
    height: 45px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    padding: 0 5px;

    #shareSpan {
      width: 50px;
      font-size: 1.1rem;
    }

    #shareInput {
      border: none;
      outline: none;
      font-size: 0.89rem;
      width: 100%;
      height: 100%;
    }

    #shareButton {
      padding: 5px 16px;
      color: #fff;
      border: 2px solid transparent;
      border-radius: 5px;
      font-weight: 500;
    }
  }
}

// Login page : /login/
.login-container {
  background: url('/static/img/angled-ad-grid.png') center center no-repeat;
  background-size: cover;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .card {
    border-radius: 15px;

    .card-header {
      border-radius: 15px;
    }

    .form-control {
      @include grey-form-control;
    }
  }
}

// Learning set container : /sets#/learning/new
.learning-set-container {
  .item-sets-content {
    height: calc(100vh - 16.5rem);
    overflow: hidden;
  }

  .items-container {
    height: calc(100vh - 22rem);
    overflow: auto;
  }
}

// Contact us (before logged in)
#contact-form{
  .form-control{
    background-color: #F4F4F4;
    border-radius: 5px;
    border: 0;
  }
}

// Privacy policy: privacy/
.privacy-policy-body{
  table{
    margin-bottom: 2rem;
    border: 1px solid;
    td, th{
      padding: 1rem 0.5rem 0.5rem 0.5rem;
      border: 1px solid;
      text-align: left;
    }

    th{
      text-align: center;
    }
  }

  blockquote{
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 40px;
    margin-inline-end: 40px;
  }
}

// Privacy policy: privacy/
.privacy-policy-body{
  table{
    margin-bottom: 2rem;
    border: 1px solid;
    td, th{
      padding: 1rem 0.5rem 0.5rem 0.5rem;
      border: 1px solid;
      text-align: left;
    }

    th{
      text-align: center;
    }
  }

  blockquote{
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 40px;
    margin-inline-end: 40px;
  }
}

// Sign up page: /sign-up
.sign-up-container{
  .form-control, .custom-select {
    @include grey-form-control;
  }

  .formulate-input[data-classification=text] input, .formulate-input[data-classification=select] select{
    background-color: $extra-light-grey !important;
  }

  .formulate-input .formulate-input-element{
    max-width: none !important;
  }
}

// Tags page: /ad/{ad_id}/tags
.ad-tags-table {
  .constrain-width {
    max-width: 250px;
  }
}

// Pixel and conversion page: /settings#/pixel-and-conversions
.pixel-status-container{
  transition: width 0.5s;
}

// Data Source page
.data-source-display-template{
  width: 92%;
}

.data-source-entry{
  .edit-btn{
    top: 2rem;
  }
}
.add-advertisers-button-container {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

// Error page navbar header: frontend/templates/error_page_navbar.html
.error-page{
  z-index: 1040 !important;
}
// DefaultAdPreview: override AdEditHtmlAdPreview's style
.default-ad-preview {
  #masonry-container {
    height: auto !important;
    margin: 0 !important;
    .masonry-item {
      padding: 0 !important;
      border: 1px solid black;
    }
  }

  .logo-upload-button {
    min-height: 6rem;
  }
}

// Punisher modal in Punisher Report
#punisherModal {
  top: -7rem;
  left: 0rem;
  z-index: 9999;

  .modal-body {
    max-height: 27rem;
    overflow-y: scroll;
  }

  table {
    td, th {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }
  }
}

.ad-overlay.hide,
.ad-iframe.hide {
  z-index: -1;
}

// AdvertiserSettingsEntry.js
.advertiser-setting-container {
  .list-group-item {
    &.custom-themes {
      background-color: $light-sage;

      &.active{
        background-color: $teal-lk;
        border-color: $teal-lk;

        .dog-plus-svg-container {
          svg {
            path {
              fill: $bone;
            }
          }
        }
      }
      .dog-plus-svg-container {
        svg {
          path {
            fill: $teal-lk;
          }
        }
      }
    }
  }
}

// Google Ads Tags Help page
.google-ads-tags-img {
  width: 100%;
  height: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  }

.google-ads-tags-hr-div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2vh;
  padding: 5px 0;
  margin-bottom: 10px;
  }

.google-ads-tags-hr {
  width: 100%;
  border: 1px solid black;
  margin: 2px 0;
}
