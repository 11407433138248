.item-sets {
    margin-top: -6rem;
    .item-sets-container {
        position: relative;
        z-index: 2;
        // margin-top: -2rem;
        .item-sets-content {
            height: calc(100vh - 14.5rem);
            overflow: hidden;
            .sets-list-container {
                // Left hand side display sets list
                background: white;
                max-height: 100%;
                .sets-list-header{
                    .sort-by-dropdown{
                        .dropdown-menu{
                            li{
                                &.selected{
                                    background: $gray-200 !important;
                                }
                            }

                        }
                    }
                }
                .sets-container {
                    min-width: 17rem;
                    max-width: 17rem;
                    max-height: calc(100% - 4rem);
                    overflow-y: scroll;
                    a{
                        .sets{
                            position: relative;
                            z-index: 1;
                            color: $spruce;
                            .highlight-cover{
                                top:0;
                                left:0;
                                bottom:0;
                                right:0;
                                z-index: -1;
                                -webkit-animation: blinking 2s ease-in-out both;
                                animation: blinking 2s ease-in-out both;
                            }
                            .itemset-name{
                                cursor: pointer;
                                &:hover{
                                    text-decoration: underline; 
                                }
                            }
                            .itemset-item-count{
                                opacity: .5;
                            }
                        }
                        &.selected {
                            .sets {
                                background: $forrest;
                                color: $white;

                                .itemset-name{
                                    font-weight: bold;
                                }

                                .itemset-item-count{
                                    opacity: 1;
                                }
                            }

                            .set-type-pill {
                                color: $white;
                                background: $secondary;
                            }
                        }
                    }
                    .set-type-pill {
                        color: $secondary;
                        border: 1px solid $secondary;
                        background: transparent;
                    }
                }
            }

            .set-details-container {
                // Right hand side display item set details
                .itemset-details-section {
                    .separator {
                        font-size: 0.3rem;
                        vertical-align: middle;
                    }
                    .itemset-items {
                        overflow-x: hidden;
                        overflow-y: scroll;
                        max-height: calc(100% - 7rem);
                        max-width: 58.5rem;
                        .filter {
                            .item-card {
                                margin-right: 0.7rem;
                            }
                        }
                    }
                    .item-filter-or-type {
                        .applied-filters-container {
                            max-height: 1.65rem;
                            overflow: hidden;
                            transition: max-height .5s ease-in;
                            &.expanded {
                                max-height: 10rem;
                            }
                            > button {
                                position: absolute;
                                top: 0;
                                right: 0;
                                transition: transform .25s ease-in;

                                &.hide {
                                    display: none;
                                }

                                &.rotated {
                                    transform: rotateZ(-180deg);
                                }
                            }
                            .applied-filters{
                                > span{
                                    flex: 0.6;
                                }
                                > div{
                                    flex: 2;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@-webkit-keyframes blinking {
    0%,
    50%,
    100% {
        opacity: 0;
        background-color: none;
    }
    25%,
    75% {
        opacity: 1;
        background-color: $marigold-v;
    }
}
@keyframes blinking {
    0%,
    50%,
    100% {
        opacity: 0;
        background-color: none;
    }
    25%,
    75% {
        opacity: 1;
        background-color: $marigold-v;
    }
}
  