@import "../colorVariables.scss";

.ad-designer-modal, .dh-ad-design-modal {
  z-index: 1040;
  background: #fff;
  .tooltip {
    pointer-events: all;
    .tooltip-inner {
      display: flex;
      flex-direction: column;
      padding: 1rem;
    }
  }
  .modal-dialog {
    .modal-content {
      .modal-header {
        justify-content: flex-start;
        div {
          .btn-close {
            width: 3%;
            background: transparent;
          }
        }
        .confirm-quit-modal {
          .modal-backdrop {
            z-index: -1;
          }
        }
      }
      .modal-body {
        height: calc(100% - 65px);
        // For letting tooltip shine out by override button disabled opacity
        .change-button:disabled,
        .change-button.disabled {
          opacity: 1 !important;

          .button-content {
            opacity: 0.65;
          }
        }
        .change-button {
          color: $gray-900;
          background-color: transparent;

          .new-version-icon {
            width: 2.9rem;
            position: relative;

            p {
              z-index: 2;
              text-align: center;
              text-transform: uppercase;
              position: absolute;
              font-size: 0.45rem;
              top: 0.65rem;
              font-weight: bold;
            }

            .certificate-icon {
              position: absolute;
              top: 1rem;
              right: 1rem;
              z-index: 1;
              scale: 3.6;
              // Use bootstrap warning color hex (#ffc107) convert to css filter
              filter: invert(63%) sepia(99%) saturate(424%) hue-rotate(358deg)
                brightness(104%) contrast(102%);
            }
          }
        }
        .formulate-input[data-classification="file"]
          .formulate-input-upload-area {
          width: 150px;
          height: 70px;
        }
        .container-fluid {
          //54px is height of .change-button row
          height: calc(100vh - 54px);
          height: -o-calc(100vh - 54px); /* opera */
          height: -webkit-calc(100vh - 54px); /* google, safari */
          height: -moz-calc(100vh - 54px); /* firefox */

          .ad-designer-conatiner-left {
            overflow-x: hidden;
            overflow-y: scroll;

            .video-edit .form-group label{
              font-weight: bold;
            }

            .palette-icons {
              color: $eggplant;
              font-size: 1.4rem;
            }

            .formulate-input[data-classification='text'] input[type="color"]{
              min-width: 3em;
              background-color: #e7e7e7;
            }

            .color-picker{
              width: 5rem;
              .color-picker-toggle {
                display: inline-block;
                width: 1.5rem;
                height: 1rem;
                background-color: #000;
                cursor: pointer;
                border-radius: 4px;
              }
  
              .vc-chrome {
                position: absolute;
                top: -16rem;
                right: -7rem;
                z-index: 9;
              }

              .current-color-value:hover{
                color: $cedar;
              }
            }
            
            .save-colour-palette-btn{
              height: 2rem;
              cursor: pointer;
              button{
                background: transparent !important;
                opacity: 1;
                .fa-bookmark{
                  color: $primary;
                }
              }
            }

            .confirm-save-colour-palette-modal {
              .modal-backdrop {
                z-index: -1;
              }
            }

            .advanced-options-section {
              margin-bottom: 6rem;

              div[aria-expanded="true"] .arrow-down {
                transition: 0.5s;
                transform: rotate(180deg);
              }
              
              // Override font-picker library style
              .font-picker{
                box-shadow: unset;
                border: 1px solid #cecece;
                border-radius: 0.3em;
                .dropdown-button{
                  background: transparent;
                  .dropdown-icon{
                    display: none;
                  }
                }
                .font-list{
                  top: -202px;
                  background: white;
                  border: 1px solid #cecece;
                  opacity: 0;
                }

                &.expanded{
                  .font-list{
                    opacity: 1;
                  }
                }
              }
              .angle-down-icon{
                position: absolute;
                right: 27%;
              }
            }

            .footer {
              left: 0;
              bottom: 0;
              background-color: white;
              z-index: 4;
            }

            .video-edit {
              .video-background-btn {
                height: 9rem;
              }
              .video-background-btn:focus {
                background-color: transparent;
              }
              fieldset{
                padding: 0;
              }
            }
          }
          // Ad theme preview
          .ad-designer-conatiner-right {
            top: 0;
            right: 0;
            height: 96.5%; // Saving space for horizontal scroll bar at the bottom
            background-color: #e7e7e7;
            z-index: 100;
            padding-top: 1rem;
            transition: all 500ms ease;

            .header-row {
              z-index: 3; // Higher than loading overlay
              background-color: rgba(231, 231, 231, 0.5);
            }

            // Loading overlay on top of ad preview iframe
            .ad-overlay {
              box-shadow: inset 0 0 0 1000px rgba(255, 255, 255, 0.5);
              transition: opacity 2s;
              z-index: 2;
              top: -0.5rem;
            }

            .ad-iframe {
              transition: opacity 1s;
              z-index: 1;
            }

            .ad-overlay.hide,
            .ad-iframe.hide {
              z-index: -1;
            }

            .addtional-margin {
              // Cannot use % as that will change base on iframe's size and will
              // causing overlapping for the iframe and the header
              margin-top: 11rem;
            }
            #masonry-container{
              overflow-x: auto;
              white-space: nowrap;
              width: 99%;
              height: auto !important; // Override libraries' height style
            }
            masonry-item {
              margin-bottom: 20px;
            }
            .iframe-container {
              position: relative;
            }
          }
          
          // Scroll Bar
          .ad-designer-conatiner-right::-webkit-scrollbar {
            // height: 2em;
            display: auto;
          }
        }
      }
    }
  }
}

// Unset the max with for formulate inputs
.formulate-input .formulate-input-element {
  max-width: unset;
}

  /** UTM tooltip **/
.tooltip.b-tooltip.utm-tooltip {
  opacity: 1.0;

  .tooltip-inner {
    max-width: 300px;
  }

}

/*
* DH video ad related
**/

.dh-video-ad-preview{
  #dh-container{
    height: calc(100vh - 6.64rem);
    z-index: 1;
  }
  
  
}

.dh-ad-design-modal{
  .modal-dialog{
    .modal-content{
      .modal-body{
        height: calc(100vh - 6rem);
      }
    }
  }

  .size-selection-container{
    width: 16rem;
  }

  .loading-overlay{
    top: 6.3rem !important;
  }

  .design-menu-toggle{
    div{
      justify-content: space-evenly;
    }
  }

  .design-menu-icon{
    width: 1.5rem;
  }

  .change-button{
    width: 9.1rem;
    .button-content{
      p{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .loading-overlay{
    z-index: 9 !important;
  }

  .updated-indicator{
    background-color: #ff0000;
    border-radius: 6px;
    color: white;
    padding: 5px 5px;
    position: absolute;
    top: -4px;
    right: -3px;
  }

  .brand-section{
    background: #ffffff;
    width: 18.2rem;
    bottom: -38rem;
    left: 0;
    z-index: 2;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    overflow: visible !important;

    &.show{
      bottom: 0;
    }

    div[aria-expanded="false"] .arrow-down {
      transition: 0.5s;
      transform: rotate(180deg);
    }

    .logo-upload-button {
      min-height: 6rem;
    }

    .palette-row {
      scale: .87;
    }
  }
}
