
// Advertiser setting - Integration tab
.integrations-container{
  @media (min-width: 992px) { 
    .col-4, .col-lg-4{
      max-width: 32%;
    }
   }
  }
  
  .integration-switch{
    input[type="checkbox"] {
      + {
        .custom-control-label {
          &::before {
            border-color: $disable;
            background-color: $disable;
          }
          &::after {
            border-color: $white;
            background-color: $white;
          }
        }
      }
    }
  }
  
  .integration-switch.checked {
    input[type="checkbox"] {
      + {
        .custom-control-label {
          &::before {
            border-color: $success;
            background-color: $success;
          }
        }
      }
    }
  }

  .integration-switch.pending{
    input[type="checkbox"] {
      + {
        .custom-control-label {
          &::before {
            border-color: $marigold-v;
            background-color: $marigold-v;
          }
          &::after {
            border-color: $white;
            background-color: $white;
          }
        }
      }
    }
  }
  
  #integration-modal{
    .loading-overlay.select-field-loading-overlay{
      z-index: 2;
      top: unset !important;
  
      &.hide{
        display: none !important;
      }
    }

    .loading-overlay{
      &.hide{
        display: none !important;
      }
    }
  
  }
  
  
  .filterable-dropdown{
    .dropdown-toggle::after {
      vertical-align: middle !important;
      text-align: right;
    }
  
    .dropdown-menu{
      top: -5rem !important;
    }
    
    .search-input-group{
      width: 88%;
    }
  
    .select-item-list, .no-search-result{
      height: 500px;
      overflow-y: scroll;
      overflow-x: hidden;
      width: 27rem;
      max-width: 27rem;
  
      .item{
        position: relative;
  
        .fade-for-text{
            display: block;
            margin: 0;
            padding: 0;
            position: absolute;
            top: 1px;
            z-index: 98;
            background: #fff;
            background: -webkit-linear-gradient(left,rgba(255,255,255,0),rgba(255,255,255,255));
            background: -webkit-gradient(linear,left top,right top,from(rgba(255,255,255,0)),to(rgba(255,255,255,255)));
            background: linear-gradient(left,rgba(255,255,255,0),rgba(255,255,255,255));
            -ms-filter: "progid:DXImageTransform.Microsoft.gradient(gradientType=1, startColorstr='#00ffffff', endColorstr='#ffffffff')";
            background-color: transparent;
            height: 28px;
            right: 0;
            width: 100px;
        }
      }
    }
  }
  
.v-popper__inner { 
  width: 500px;
  max-width: 560px !important; 
}

// Feed status template
.feed-status-template{
  .loading{
    height: 10rem;
  }
}

.default-template{
  .ready-icon.fa-2xl{
    font-size: 7rem;
  }
}
  
  