@import '../utilities.scss';

.btn-tier-restriction{
  @include dog-plus-button;
  font-weight: normal !important;
}

.btn-tier-restriction-default{
  @include dog-plus-svg-fill-colors;
}

.btn-dropdown-tier-restriction{
  @include dog-plus-svg-fill-colors;
}

// TODO: keeping the bg image switching code here to serve buttons that are inside Django template, remove them when we no longer need those buttons inside Django template
// Only for buttons in Django templates
.btn-tier-restriction-html{
  @include dog-plus-button-bg-image;
  font-weight: normal !important;
}

.tier-modal-template{
  .left-container{
    .btn-upgrade-plan{
      @include dog-plus-button;
    }
  }

  .right-container{
    background: $surface-primary;
  }
}

.tier-upgrade-button-container{
  .btn-outline-dark{
    &:hover{
      background-color: unset !important;
      color: unset !important;
    }
  }
}