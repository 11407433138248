@font-face {
    font-family: Montserrat;
    src: url(/static/fonts/Montserrat/Montserrat-Regular.ttf) format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: Montserrat;
    src: url(/static/fonts/Montserrat/Montserrat-SemiBold.ttf) format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: Montserrat;
    src: url(/static/fonts/Montserrat/Montserrat-Bold.ttf) format('truetype');
    font-weight: 700;
}

$font-weight-600: 600;
$font-weight-800: 800;
$font-size-base: 0.9rem;
$font-size-sm: 0.7rem;
$font-size-md: 0.85rem;

.font-weight-600 {
    font-weight: $font-weight-600;
}

.font-weight-800 {
    font-weight: $font-weight-800;
}

.font-size-sm {
    font-size: $font-size-sm;
}

.font-size-md {
    font-size: $font-size-md;
}
