
.navbar.retriever-top-nav{
  background-color: $mint;
  min-height: 70px;
  // Alignment of caret down icon next to toggle
  .dropdown-toggle::after {
    vertical-align: middle !important;
    margin-left: 1.255em;
    color: $eggplant;
  }

  .username-dropdown-button {
    background: $cedar;
    background-position: left center;
    vertical-align: middle;
    min-width: 40px;
    width: 40px;
    height: 40px;
    font-size: 1.5rem;
    color: white;
  }

  .account-dropdown button .dropdown-toggle{
    font-weight: 800;
    color: $eggplant;
  }

  // Set spacing around and same style to the advertiser name
  // to prevent other nav item shift after advertiser-menu loaded
  #advertiser-menu-app{
    font-size: 1.2rem;
    padding-left: 1.8rem;
    margin-right: 2.5rem;
  }

  .btn-tier-restriction{
    min-width: 202px;
  }

  .advertiser-menu {

    .tiered-advertiser-btn{
      img {vertical-align: top;}
      background: $light-sage;
      border: 2px solid rgba(183, 223, 211, 0.20);
    }

    .selected-advertiser-name {
      max-width: 21.5rem;
      font-size: $font-size-base + 0.1;
      color: $spruce;
      font-weight: 800;
    }

    .dropdown-toggle p {
      max-width: 13rem;
    }

    .dropdown-menu {
      width: 15rem;

      // For getting correct size of the dropdown for scroll detection
      &.temp-show{
        display: block;
        opacity: 0; // We do not need to visually see the dropdown
      }
    }

    .dropdown-menu {
      top: -3.10rem !important;
      left: -0.4rem !important;
      width: 32rem;
      padding-left: 1.1rem;
      padding-bottom: 0 !important;

      .dropdown-menu-container {
        min-height: 16rem;
      }

      :focus {
        box-shadow: none;
      }

      .advertisers {
        background: transparent;

        &:hover,
        &.selected {
          background: #f8f9fa;
          text-decoration: none;
        }

        &.selected {
          font-weight: bold;
          color: $eggplant;
        }
      }

      .advertiser-list-container {
        &.hide, .load-advertisers-overlay.hide{
          display: none !important;
        }
        
        .advertisers-lists {
          max-height: 23rem;
          overflow: auto;
          padding-bottom: 5rem;

          .recent-advertisers {
            &.hide {
              display: none;
            }
          }

          .advertiser-create {
            bottom: 2rem;
            right: 2rem;
          }
        }
      }

      .search-container {
        background: #fff;
        top: 0;
        left: 0;

        &.hide {
          display: none;
        }

        .header-row {
          .search-input-group {
            .input-group-text {
              background: transparent;
            }
          }
        }

        .search-result-container {
          min-height: 13rem;

          .search-overlay{
            top: 0; 
            
            &.hide{
              display: none !important;
            }
          }
        }
      }

      .filter-container {
        &.hide {
          display: none;
        }

        .heading {
          font-size: 1.2rem;
        }
      }
    }
  }
}