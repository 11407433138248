.itemset-edit-custom {
    .page-title {
        margin-top: 5rem;
    }

    .btn {
        height: fit-content;
    }

    .editable-name-control {
        margin-top: -0.5rem;
        margin-left: -0.75rem;
        background-color: transparent;
        border-color: transparent;
        &:hover {
            border-color: $input-border-color;
        }
        &:focus {
            border-color: $input-border-color;
            color: $eggplant;
        }
    }
    
    .itemset-edit-custom-pop-over .arrow::after {
        border-top-color: #17a2b8;
    }

    .itemset-edit-content {
        overflow: hidden;
        height: calc(100vh - 13.3rem);


        &.short {
            height: calc(100vh - 19rem);
        }

        &.mid-short {
            height: calc(100vh - 17rem);
        }

        .filter-container>div {
            height: 100%;
        }

        .filter-container{
            .filters-content {
                overflow: auto;
                height: calc(100% - 4rem);

                form {
                    .filter-options-container{
                        min-height: 14rem;
                    }
                }
            }
        }
        
        .items-container {
            overflow: auto;
            height: calc(100% - 5rem);

            .product-grid {
                display: grid;
                grid-template-columns: repeat(auto-fill, 180px);
                justify-content: space-between;
            }
        }
    }
}