.item-details-modal-body {
	max-height: 80vh;
	min-height: 70vh;
	.switch-label {
		line-height: 2rem;
	}
	.item-thumbnail-container {
		width: 100%;
		min-height: 26rem;
		img {
			transition: 2s;
			z-index: 1;
		}
	}
	.item-status-switch {
		input[type="checkbox"] {
			+ {
				.custom-control-label {
					&::before {
						border-color: $success;
						background-color: $success;
					}
					&::after {
						border-color: $white;
						background-color: $white;
					}
				}
			}
		}
	}
	.item-status-switch.checked {
		input[type="checkbox"] {
			+ {
				.custom-control-label {
					&::before {
						border-color: $danger;
						background-color: $danger;
					}
				}
			}
		}
	}
}