// Themes components' style under /Django/retriever/frontend/static/js/src/themes
@import "../colorVariables.scss";

.themes-management {
  &.admin_tools{ // Special styling update for admin tools > Themes
    .page-header {
      top: -2.9rem;
    }

    .breadcrumb {
      top: -4.5rem;
    }
  }

  .breadcrumb {
    top: -6rem;
    left: -19.5rem;

    li {
      span {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .page-header {
    top: -3.9rem;
    left: -16.9rem;
  }

  .page-loading-overlay{
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    z-index: 1040;
    opacity: 0.5;
  }

  //Theme details page
  .theme-details-container {
    .dropzone-error {
      position: absolute;
      z-index: 9;
      top: 0;
      bottom: 0;
      opacity: 0.5;
      left: 0;
      right: 0;
    }
  }

  // Themes list page
  .themes-list-container {
    .theme-card-container {
      .theme-card {
        width: 15.8rem;
        height: 16.6rem;
        .theme-preview-iframe-container{
          scale: 0.841;
          transform-origin: left top;
          margin-bottom: 0 !important;
        }
        .overlay-details-container{
          .theme-name-container{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        
        &:hover {
          .overlay-details-container {
            max-height: 15rem;
            overflow: visible;

            .more-details {
              opacity: 1;
            }
            .theme-name-container{
              white-space: unset;
            }
          }
        }

        .theme-preview-iframe-cover{
          z-index: 2;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          background: transparent;
          cursor: pointer;
        }

        .theme-preview-iframe{
          top: 0;
          left: 0;
          z-index: 0;
        }

        .overlay-details-container {
          bottom: 0;
          transition: max-height 0.3s ease;
          max-height: 3.2rem;
          overflow: hidden;
          z-index: 3;

          .more-details {
            opacity: 0;
            transition: opacity 0.1s ease-out;

            .version-badge-container {
              top: -1rem;
              right: 1rem;
              width: 2.2rem;
              height: 2.2rem;

              .version-badge {
                box-shadow: 0 4px 7px 0px rgba(50, 50, 93, 0.25);
                -webkit-box-shadow: 0 4px 7px 0px rgba(50, 50, 93, 0.25);
                -moz-box-shadow: 0 4px 7px 0px rgba(50, 50, 93, 0.25);
              }
            }
          }
        }
      }
    }
  }
}

.version-details-container {
    .loading-overlay {
      z-index: 2;
      top: 0.3rem;
      bottom: 0;
    }

    .ads-list-container{
      height: 15rem;
      .dropdown-item{
        width: 300px;
        height: 250px;
      }
    }

    .form-control{
      // Overriding bootstrap styling
      .input-group-append{
        margin-top: -0.5rem;
        .input-group-text {
          font-size: 2rem;
        }
      }
    }
}

/** Theme preview page **/
.theme-preview-container{
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  z-index: 1040;
  // Override container styling
  max-width: none;

  .loading-overlay{
    &.show{
      opacity: .5;
      z-index: 1040;
    }

    &.hide{
      display: none !important;
    }
  }

  .footer {
    left: 0;
    bottom: 0;
    z-index: 4;
  }
  
  .page-content{
    height: calc(100% - .58rem);


    .settings-container{
      padding-left: 6.7rem;
      //TODO need to fact check the height
      overflow-y: auto;
      height: inherit;

      .preview-setting-section {
          .filter-section {
            div.row, hr{
              display: none;
            }
            .item-filter-panel-container{
              .item-search-filter-panel-container{
                // Overriding inline styling as this component reuse in serval places
                padding-left: 0 !important;
                padding-top: 0 !important;
              }
            }
          }
        }
      }

    .preview-container{
      background: $light-grey-v;
      .refresh-button:disabled{
        opacity: 0.2;
      }
      .theme_iframe-wrapper{
        overflow-y: auto;
        height: 86vh;
        .theme-preview-iframe-container{
          .loading-overlay.show{
            opacity: 0.5;
          }
        }
      }
    }
  }
}

// Common style for file dropzone
.file-dropzone {
  .dropzone {
    padding: 1rem 0;
    min-height: fit-content;

    &.darken-hover{
        &:hover{
          filter: brightness(95%);
        }
    }

    .dz-message {
      margin: 0;

      .error-view{
        &:hover{
          filter: brightness(95%);
        }
      }
    }
  }
}

.publish-file-dropzone{
  .collapse-dropzone-btn{
    bottom: 0;
    right: 0;
    z-index: 1;

    button{
      background: none;
      border: none;
    
      &:hover{
        background-color: #f6f6f6;
        border-color: #d3d9df;
      }

      i{
        font-size: 1.5rem;
      }
    }
  }
}
