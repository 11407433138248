.video-library {
    &.disabled button{
        opacity: 0.65;
        pointer-events: none;
    }

    /* Video selector button */
    .video-selector-button{
        min-height: 10rem;
        min-width: 20rem;
        background: transparent;

        &:focus, &:active{
            background: transparent;
        }

        &:hover{
            background-color: #eee;
        }
    }

}
  
#video-library-modal{
    .tab-content{
        margin-top: 0 !important;
        border: 1px solid #dee2e6;
        border-top: none;
        // height: 73vh;
    }

    .video-tab-component{
        height: 73vh;
    }

    /* Video management tab */
    .video-management-tab-content{
        overflow-y: auto;

        .circled-number-top-right {
            position: absolute;
            top: 0.25rem;
            right: 0.25rem;
            background-color: lightgray;
            color: white;
            border-radius: 50%;
            width: 24px; /* Adjust the size of the circle */
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px; /* Adjust the font size of the number */
        }

        .content__asset {
            cursor: pointer;
            &.disabled{
                opacity: 0.5;
                pointer-events: none;
            }
            // Overriding bootstrap class
            &.col-3{
                max-width: 23.8%;
            }
            .asset-group-title {
                font-size: 10px;
            }
            .transparent-btn {
                position: absolute;
                bottom: 0.25rem;
                right: 0.25rem;
                background: none;
                border: none;
                display: none;
            }
            &:hover .transparent-btn {
                display: block;
            }
        }
    }

    /* Video upload tab */
    .video-upload-tab-content{
        .dropzone-container{
            // max-height: 29rem;
            overflow-y: scroll;
            height: 87%;
        }

        .vue-dropzone{
            min-height: 0;
            &.small-dropzone{
                min-height: 5rem;
                .dz-message{
                    margin: 0;
                    padding-top: 0.6rem;
                    .file-selector {
                        .fa-file-video-o{
                            line-height: 1.4rem;
                        }
                        p{
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        // Uploaded file list
        .temp-uploaded-video-list{
            .file-name{
                .dz-remove{
                    color: $dark-grey-v;
                    &.hide{
                        display: none;
                    }
                }
                .fa-2xs{
                    font-size: .30rem;
                }
            }
            .file-info{
                .status{
                    .error-msg:first-letter{
                        text-transform: capitalize;
                    }
                }

                #tooltip-container{
                    .tooltip-inner{
                        text-align: left;
                        max-width: fit-content;
                        padding: 1rem;
                        ul{
                            padding-left: 1.5rem;
                            list-style: disc;
                        }
                    }
                }
            }
        }

        .asset-group-saved-message, .asset-group-error-message{
            &.hide{
                display: none;
            }
        }

        .save-video-group-btn.disabled{
            cursor: not-allowed;
        }

    }
}



  

  
  
  