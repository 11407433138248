// AdsTable
#ads-list-table {
    thead th {
        vertical-align: middle;
        white-space: nowrap;
    }

    .platform-icon {
        height: 20px;
    }

    // Ad list status
    .ad-list-status-pill {
        width: fit-content;

        &.badge-secondary {
            @include grey-badge;
        }
    }
}

// Connection modal in Ads page
#connection-modal {
    .loading-overlay {
        z-index: 2;

        &.hide {
            display: none !important;
        }
    }

    .platform-icon {
        height: 16px;
        vertical-align: baseline;
    }

    .card-header {
        .arrow-down {
            transition: 0.5s;
        }

        &[aria-expanded="true"] {
            .arrow-down {
                transform: rotate(180deg);
            }
        }
    }

    .cm360-connection-template {
        .card {
            overflow-y: scroll;
            height: calc(100vh - 380px);
        }

        .loading-overlay {
            &.hide {
                display: none !important;
            }
        }
    }

    #campaigns-accordion {
        .sites-multiselect {
            height: fit-content;
    
            .multiselect-tag {
                white-space: normal;
            }
    
            .multiselect-dropdown {
                box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
            }
    
            .multiselect__content-wrapper {
                position: static;
            }
        }
    }

    // CampaignSitesRow in ads page connection modal
    .campaign-sites-row {
        .custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
            background-color: $disable !important;
            border: $disable !important;
            opacity: 0.5;
        }

        .disabled-text{
            opacity: 0.6;
        }

        &.selected{
            background-color: #fff3cd;
            color: #856404;
            border-radius: 0.3rem;
        }
    }
}

// Google Ads SpreadsheetModal
#generateTags{
    .modal-height-maintainer{
        height: 250px;
    }
}
