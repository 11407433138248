.widget-title {
    display: inline;
}

.dashboard-component {
    border-radius: 15px;
    padding: 2rem;
    margin-bottom: 2rem;
}

.first-create-wrapper {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.first-create-wrapper::before {
    position: absolute;
    top: calc(26% - 1px);
    right: 0;
    left: 0;
    Content: "";
    background-color: $cedar;
    height: 2px;
    // width: 80%;     if set included
    width: 75%;
    margin: 0 auto;
}

.first-create-icon {
    color: $river;
    font-size: 1.7rem;
    position: relative;
    z-index: 1;
    padding: .5rem;
    background: #fff;
    box-shadow: 0 0 0 0.5em #fff
}

.first-create-text {
    color: $text-light;
    font-size: 1rem;
}

.data-source-label {
    color: $text-light;
    font-size: .9rem;
    white-space: nowrap;
}

.stacked-data-source-label td[data-label]:before {
    color: $text-light;
    font-size: .9rem;
}

.widget-height {
    height: 400px;
    overflow: hidden;
}

.widget-height .table-max-height {
    max-height: 325px;
}

.data-source-value {
    color: $secondary;
    font-size: 1rem;
    white-space: nowrap;
}

.big-number {
    font-size: 1.5rem;
    color: $secondary;
}

.data-source-pill-positive {
    font-size: .8rem;
    background-color: #DFFAEF !important;
    color: #3B6354 !important;
    padding: .5rem .4rem;
    min-width: 1.8rem;
}

.data-source-pill-negative {
    font-size: .8rem;
    background-color: #FFECEC !important;
    color: #AB2524 !important;
    padding: .5rem .4rem;
    min-width: 1.8rem;
}

.data-source-pill-neutral {
    font-size: .6rem;
    background-color: #e1e8ef !important;
    color: #6c757d !important;
    padding: .5rem .4rem;
    min-width: 1.8rem;
}

.fa-rotate-45 {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.fa-rotate--45 {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.fa-rotate--38 {
    -webkit-transform: rotate(-38deg);
    -moz-transform: rotate(-38deg);
    -ms-transform: rotate(-38deg);
    -o-transform: rotate(-38deg);
    transform: rotate(-38deg);
}

.data-source-link {
    font-size: .9rem;
}

.horizontal-scrollable {
    overflow-x: scroll;
}


// chart override styles
.apexcharts-svg.apexcharts-zoomable.hovering-zoom {
    cursor: auto !important;
}

.apexcharts-canvas {
    margin: 0 auto
}

.apexcharts-xaxis-label {
    fill: $text-light;
    color: $text-light;
    overflow: visible;
}

.apexcharts-tooltip {
    color: $grass;
}

.no-data {
    color: $text-light;
}

.mt-login {
    margin-top: 2.5rem
}

.smaller-font-size {
    font-size: .9rem;
}

// data source settings icons
.layered-icons {
    padding: .5rem;

    .bottom-layer-icon {
        font-size: 2.5rem;
    }

    .top-layer-icon {
        z-index: 999;
        margin-left: -.5rem;
        padding-top: .25rem
    }
}

.data-source-expand {
    color: #555;
    font-size: 1.3rem;
}

.data-source-muted {
    color: rgba(0, 0, 0, 0.5);
    font-size: .85rem;
}

.data-source-small {
    color: rgba(0, 0, 0, 0.8);
    font-size: .85rem;
}

.close {
    &:hover {
        color: $secondary;
        opacity: 1 !important;
    }
}



.top-ads-by-ctr {
	.topColumn.name {
		width: 6rem;
		position: relative;
	}
	.ellipsis {
		display: block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		transition: all .2s linear;
		white-space: nowrap;
		&:focus {
			color: transparent;
			&:after {
				content: attr(data-text);
				overflow: visible;
				text-overflow: inherit;
				background: #f8f9fa;
				position: absolute;
				left: auto;
				top: auto;
				width: auto;
				max-width: 30rem;
				white-space: normal;
				word-wrap: break-word;
				display: block;
				color: #00b0b8;
				margin-top: -1.5rem;
				z-index: 1;
			}
		}
		&:hover {
			color: transparent;
			&:after {
				content: attr(data-text);
				overflow: visible;
				text-overflow: inherit;
				background: #f8f9fa;
				position: absolute;
				left: auto;
				top: auto;
				width: auto;
				max-width: 30rem;
				white-space: normal;
				word-wrap: break-word;
				display: block;
				color: #00b0b8;
				margin-top: -1.5rem;
				z-index: 1;
			}
		}
	}
}
