@import './colorVariables';

.b-popover-info.popover {
    background-color: $grass;
    border-color: darken($grass, 5%);
}

.b-popover-info.bs-popover-top>.arrow::after {
    border-top-color: $grass;
}

.b-popover-info.bs-popover-top>.arrow::before {
    border-top-color: darken($grass, 5%);
}